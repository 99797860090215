import React from 'react';


function getFYDFormIcon(iconCode, className=false) {
  let iconClass;

  switch (iconCode) {
    case 'face-very-happy':
      iconClass = 'fa-solid fa-face-laugh-beam'
      break;
    case 'face-happy':
      iconClass = 'fa-solid fa-face-smile-beam'
      break;
    case 'face-meh':
      iconClass = 'fa-solid fa-face-meh'
      break;
    case 'face-sad':
      iconClass = 'fa-solid fa-face-frown-open'
      break;
    case 'face-angry':
      iconClass = 'fa-solid fa-face-tired'
      break;
    case 'question-circle':
      iconClass = 'fa-solid fa-circle-question'
      break;
    default:
      iconClass = null;
  }

  if (iconClass !== null) {
    return (
      <i
        className={`${iconClass} ${className}`}
      ></i>
    );
  }
  else {
    return (
      <span
        className={className}
      >

        UNDEFINED_ICON

      </span>
    );
  }
}

export {getFYDFormIcon};


function parseForTextHighlights(text, keyIdentifier, replaceCallback) {
  const boldRegex = new RegExp(/\*\S+.*\S+\*|\*\S+\*/, 'gu');

  const matches = text.matchAll(boldRegex);

    let lastIndex = 0;
    const output = [];

    for (const match of matches) {
      output.push(<React.Fragment key={`${keyIdentifier}:${output.length+1}`}>{match.input.slice(lastIndex, match.index)}</React.Fragment>);
      output.push(<React.Fragment key={`${keyIdentifier}:${output.length+1}`}>{replaceCallback(match[0].slice(1, match[0].length - 1))}</React.Fragment>);

      lastIndex = match.index + match[0].length;
    }

    if (lastIndex !== text.length) {
      output.push(<React.Fragment key={`${keyIdentifier}:${output.length+1}`}>{text.slice(lastIndex)}</React.Fragment>);
    }

    return (
      <React.Fragment>

        {output}

      </React.Fragment>
    );
}

export {parseForTextHighlights};