import React from 'react';
import {Helmet} from "react-helmet";
import { Redirect } from 'react-router'
import MainHeader from '../../components/main_header';
import MainFooter from '../../components/main_footer';
import LoadingIcon from '../../components/loading_icon';
import {RedText} from '../../components/default_elements';
import FormContent from '../form_content';
import {STUDENT_EXIT_SURVEY_GET_BY_HASH,
        STUDENT_EXIT_SURVEY_POST_BY_HASH,
        THANK_YOU_PATH,
        FORM_ANSWER_TYPE_SHOT_TEXT_ID,
        FORM_ANSWER_TYPE_LONG_TEXT_ID,
        FORM_ANSWER_TYPE_DROPDOWN_ID,
        FORM_ANSWER_TYPE_RATING_ID,
        FORM_QUESTION_SERVIVE_PLAN_TO_CANCEL_ID} from '../../constants';
import {getModel, postModel} from '../../utils/functions';
import {getFYDFormIcon, parseForTextHighlights} from '../../utils/fyd_form';
import RadioQuestion from '../radio_question';
import TextQuestion from '../text_question';
import TextAreaQuestion from '../text_area_question';
import ConfirmationWindow from '../../utils/confirmation_window';
import './customer_exit_survey.scss';


class CustomerExitSurvey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingData: true,
      confirmInProgress: false,
      confirmFailed: false,
      customer_exit_survey_form: null,
      currentQuestionOrder: 0,
      allowNext: false,
      maxOrder: 0,
      contract_options: [],
      FYDFormAnswerMap: new Map(),
      questionMap: new Map(),
      questionIdToOrder: new Map(),
      may_submit_form: false,
      screenWidth: window.innerWidth
    };
  }

  async componentDidMount() {
    this.getCustomerExitSurveyForm();

    this.onCloseWindow = (event) => {
      event.preventDefault();

      const textMessage = 'O formulário ainda não foi enviado. Tem certeza de que deseja fechar esta página?';

      event.returnValue = textMessage;
      return textMessage;
    };

    window.addEventListener("beforeunload", this.onCloseWindow);

    this.resizeListener = () => this.updateSize();

    window.addEventListener("resize", this.resizeListener);
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onCloseWindow);
    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    const update = {screenWidth: window.innerWidth};

    this.setState(update);
  }

  async getCustomerExitSurveyForm() {
    this.setState({
      loadingData: true,
    });

    const update = {loadingData: false};

    const response = await getModel(`${STUDENT_EXIT_SURVEY_GET_BY_HASH}${this.props.match.params.userHash}`);

    if(response) {
      update.contract_options = response.contract_options;
      update.customer_exit_survey_form = response.customer_exit_survey_form;

      update.contract_options.sort((a, b) => a.service_plan_name.localeCompare(b.service_plan_name));

      update.customer_exit_survey_form.questions.sort((a, b) => a.order - b.order);

      update.questionMap = new Map();
      update.questionIdToOrder = new Map();
      update.FYDFormAnswerMap = new Map();

      update.maxOrder = 0;

      for (const question of update.customer_exit_survey_form.questions) {
        if (question.order > update.maxOrder) {
          update.maxOrder = question.order;
        }

        update.questionMap.set(question.order, question);
        update.questionIdToOrder.set(question.id, question.order);
        update.FYDFormAnswerMap.set(question.order, null);
      }

      if (update.questionMap.size > 0) {
        update.currentQuestionOrder = 1;

        if (update.contract_options.length <= 1) {
          update.currentQuestionOrder = 2;

          if (update.contract_options.length === 1) {
            update.FYDFormAnswerMap.set(1, `${update.contract_options[0].service_plan_name} (ID do contrato: ${update.contract_options[0].id})`);
          }
          else {
            update.FYDFormAnswerMap.set(1, "Nenhum plano ativo encontrado");
          }
        }
      }
    }

    this.setState(update);
  }

  async onFinishClick() {
    this.setState({
      confirmInProgress: true,
      confirmFailed: false,
    });

    const data = {
      answers: [],
    };

    for (const [key, value] of this.state.FYDFormAnswerMap) {
      data.answers.push({
        answer: value,
        fyd_form_question_id: this.state.questionMap.get(key).id
      });
    }

    try {
      if(await postModel(`${STUDENT_EXIT_SURVEY_POST_BY_HASH}${this.props.match.params.userHash}`, data) == null) {
        this.setState({
          confirmInProgress: false,
          confirmFailed: true,
        });
      }
      else {
        this.setState({
          redirect_to: THANK_YOU_PATH
        });
      }
    }
    catch(errors) {
      this.setState({
        confirmInProgress: false,
        confirmFailed: true,
      });
    }

    return false;
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if(name.startsWith('fyd_form:question:')) {
      const selection = name.split(':');
      const questionOrder = parseInt(selection[2]);

      const FYDFormAnswerMap = new Map(this.state.FYDFormAnswerMap);

      FYDFormAnswerMap.set(questionOrder, value);

      const question = this.state.questionMap.get(questionOrder);

      this.setState({
        FYDFormAnswerMap,
        allowNext: !question.is_required || (target.type === 'checkbox' ? value : value.length > 0)
      });

      if((question.fyd_form_answer_type_id !== FORM_ANSWER_TYPE_SHOT_TEXT_ID && question.fyd_form_answer_type_id !== FORM_ANSWER_TYPE_LONG_TEXT_ID) && this.state.currentQuestionOrder < this.state.maxOrder) {
        setTimeout(() => {this.onNextClick();}, 100);
      }
    }
  }

  onSetFYDFormAnswer(questionOrder, answer) {
    const FYDFormAnswerMap = new Map(this.state.FYDFormAnswerMap);

    FYDFormAnswerMap.set(questionOrder, answer);

    const question = this.state.questionMap.get(questionOrder);

    this.setState({
      FYDFormAnswerMap,
    });

    if((question.fyd_form_answer_type_id !== FORM_ANSWER_TYPE_SHOT_TEXT_ID && question.fyd_form_answer_type_id !== FORM_ANSWER_TYPE_LONG_TEXT_ID) && this.state.currentQuestionOrder < this.state.maxOrder) {
      setTimeout(() => {this.onNextClick();}, 100);
    }
  }

  getContentText() {
    return this.state.customer_exit_survey_form.user_note;
  }

  getConfirmationWindowTitle() {
    if(this.state.confirmInProgress) {
      return 'Enviando dados';
    }
    else if(this.state.confirmFailed) {
      return 'Falha ao enviar dados';
    }

    return 'Indefinido';
  }

  getConfirmationWindowDescription() {
    if(this.state.confirmFailed) {
      return 'Infelizmente não foi possível realizar o envio da pesquisa ' +
             'de cancelamento. Por favor, verifique sua conexão com a internet ' +
             'e tente novamente mais tarde.';
    }

    return 'Por favor, aguarde enquanto enviamos os dados da pesquisa de cancelamento.';
  }

  onCancelConfirmation() {
    this.setState({
      confirmInProgress: false,
      confirmFailed: false,
    });
  }

  getFormQuestion(question) {
    if (question.id === FORM_QUESTION_SERVIVE_PLAN_TO_CANCEL_ID) {
      return (
        <RadioQuestion
          title={question.question_title}
          options={this.state.contract_options.map((option) => {
            return {
              text: option.service_plan_name,
              value: `${option.service_plan_name} (ID do contrato: ${option.id})`
            };
          })}
          handleInputChange={(event) => this.handleInputChange(event)}
          name={`fyd_form:question:${question.order}:`}
          value={this.state.FYDFormAnswerMap.get(question.order)}
        />
      );
    }

    let answerOptions = null;

    switch (question.fyd_form_answer_type_id) {
      case FORM_ANSWER_TYPE_DROPDOWN_ID:
        answerOptions = JSON.parse(question.answer_options);

        return (
          <RadioQuestion
            title={question.question_title}
            options={answerOptions.map((option) => {
              return {
                text: option.value,
                color: option.color,
                icon: option.icon ? getFYDFormIcon(option.icon, "customer-exit-survey__fyd-form__question__answer-options__item__icon") : null
              };
            })}
            handleInputChange={(event) => this.handleInputChange(event)}
            name={`fyd_form:question:${question.order}:`}
            value={this.state.FYDFormAnswerMap.get(question.order)}
          />
        );
      case FORM_ANSWER_TYPE_SHOT_TEXT_ID:
        return (
          <TextQuestion
            title={question.question_title}
            name={`fyd_form:question:${question.order}:`}
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.FYDFormAnswerMap.get(question.order) || ""}
          />
        );
      case FORM_ANSWER_TYPE_LONG_TEXT_ID:
        return (
          <TextAreaQuestion
            title={question.question_title}
            name={`fyd_form:question:${question.order}:`}
            rows="4"
            handleInputChange={(event) => this.handleInputChange(event)}
            value={this.state.FYDFormAnswerMap.get(question.order) || ""}
          />
        );
      case FORM_ANSWER_TYPE_RATING_ID:
        if (question.answer_options === null) {
          return (
            <p
              key={`fyd_form:question:${question.id}`}
              className="customer-exit-survey__alert-text"
            >

              <i className="fas fa-exclamation customer-exit-survey__alert-text__icon"></i>
              OPÇÕES DE PERGUNTA NÃO CONFIGURADAS CORRETAMENTE

            </p>
          );
        }

        const defaultCharacterWidth = 1.1;

        answerOptions = JSON.parse(question.answer_options);

        const minOptionWidth = defaultCharacterWidth * Math.max(...answerOptions.map((option) => option.value.length));

        answerOptions = answerOptions.map((option, index) => {
          const selected = this.state.FYDFormAnswerMap.get(question.order) === option.value;

          let itemStyle = {}; // borderColor: option.color,

          if (selected) {
            itemStyle.color = option.color;
          }
          else {
            itemStyle.background = option.color;
          }

          return (
            <li
              key={`fyd_form:question:${question.order}:answer_option:${index}`}
              className="customer-exit-survey__fyd-form__question__answer-options__item"
              style={{minWidth: `${minOptionWidth}em`}}
            >

              <button
                className="customer-exit-survey__fyd-form__question__answer-options__item__button"
                onClick={() => this.onSetFYDFormAnswer(question.order, option.value)}
                disabled={selected}
                style={itemStyle}
              >

                <p className="customer-exit-survey__fyd-form__question__answer-options__item__value">
                  {option.value}
                </p>

              </button>

            </li>
          );
        });

        return (
          <div
            key={`fyd_form:question:${question.order}`}
            className="customer-exit-survey__fyd-form__question"
          >
            <div className="customer-exit-survey__fyd-form__question__content-wrapper">

              <h4 className="customer-exit-survey__fyd-form__question__label">{parseForTextHighlights(question.question_title, `fyd_form:question:${question.order}:title`, (text) => <RedText>{text}</RedText>)}</h4>

              <ul
                className="customer-exit-survey__fyd-form__question__answer-options"
              >

                {answerOptions}

              </ul>

            </div>

            {/* {question.is_required && (
              <div className="customer-exit-survey__fyd-form__question__required-warning">

                <i className="fa-solid fa-circle-exclamation customer-exit-survey__fyd-form__question__required-warning__icon"></i>
                <p className="customer-exit-survey__fyd-form__question__required-warning__text">
                  Preenchimento obrigatório
                </p>

              </div>
            )} */}

          </div>
        );
      default:
        return (
          <p
            key={`fyd_form:question:${question.id}`}
            className="customer-exit-survey__alert-text"
          >

            <i className="fas fa-exclamation customer-exit-survey__alert-text__icon"></i>
            PERGUNTA NÃO CONFIGURADA CORRETAMENTE

          </p>
        );
    }
  }

  getFormQuestions() {
    if(this.state.questionMap.has(this.state.currentQuestionOrder)) {
      return this.getFormQuestion(this.state.questionMap.get(this.state.currentQuestionOrder));
    }

    return null;
  }

  onNextClick() {
    let nextOrder = this.state.currentQuestionOrder >= this.maxOrder ? this.maxOrder : this.state.currentQuestionOrder + 1;

    window.scrollTo(0, 0);

    let nextQuestion = this.state.questionMap.get(nextOrder);

    const update = {};

    while(nextQuestion.related_fyd_form_question_id !== null && nextQuestion.related_question_answer_for_comparison !== this.state.FYDFormAnswerMap.get(this.state.questionIdToOrder.get(nextQuestion.related_fyd_form_question_id))) {
      update.FYDFormAnswerMap = new Map(this.state.FYDFormAnswerMap);
      update.FYDFormAnswerMap.set(nextOrder, null);

      if (nextOrder >= this.maxOrder) {
        this.onFinishClick();
        return;
      }

      nextOrder += 1;
      nextQuestion = this.state.questionMap.get(nextOrder);
    }

    const nextAnswer = this.state.FYDFormAnswerMap.get(nextOrder);

    update.currentQuestionOrder = nextOrder;
    update.allowNext = !nextQuestion.is_required || (nextAnswer !== null && nextAnswer.length > 0);

    this.setState(update);
  }

  onPreviousClick() {
    let previousOrder = this.state.currentQuestionOrder > 1 ? this.state.currentQuestionOrder - 1 : 1;

    window.scrollTo(0, 0);

    let previousQuestion = this.state.questionMap.get(previousOrder);

    while(previousOrder > 1 && previousQuestion.related_fyd_form_question_id !== null && previousQuestion.related_question_answer_for_comparison !== this.state.FYDFormAnswerMap.get(this.state.questionIdToOrder.get(previousQuestion.related_fyd_form_question_id))) {
      previousOrder -= 1;
      previousQuestion = this.state.questionMap.get(previousOrder);
    }

    const previousAnswer = this.state.FYDFormAnswerMap.get(previousOrder);

    this.setState({
      currentQuestionOrder: previousOrder,
      allowNext: !previousQuestion.is_required || (previousAnswer !== null && previousAnswer.length > 0)
    });
  }

  render() {
    if(this.state.redirect_to) {
      return (
        <Redirect push to={this.state.redirect_to} />
      );
    }

    return (
      <React.Fragment>

        <Helmet>
          <title>Pesquisa de cancelamento - FYD Club</title>
        </Helmet>

        <MainHeader
          location={this.props.location}
          collapse={true}
        />

        <div className="customer-exit-survey__wrapper">

          <section className="customer-exit-survey">

            <div className="customer-exit-survey__content">

              {this.state.loadingData ?
                <LoadingIcon />:
                <FormContent
                  contentTitle={parseForTextHighlights(this.state.customer_exit_survey_form.title, 'customer_exit_survey:title', (text) => <RedText>{text}</RedText>)}
                  contentText={this.getContentText()}
                  questionsId={this.state.currentQuestionOrder}
                  questions={this.getFormQuestions()}
                  onNextClick={() => this.onNextClick()}
                  onPreviousClick={() => this.onPreviousClick()}
                  onFinishClick={() => this.onFinishClick()}
                  maxId={this.state.maxOrder}
                  minId={1}
                  // showControllers={this.state.may_submit_form}
                  allowNext={this.state.allowNext}
                  showControllers={true}
                  finishText="Enviar"
                  uploadingText="Enviando..."
                />
              }

            </div>

          </section>

          <MainFooter
            collapse={true}
          />

        </div>

        <ConfirmationWindow
          title={this.getConfirmationWindowTitle()}
          description={this.getConfirmationWindowDescription()}
          cancelText="Ok"
          visible={this.state.confirmInProgress || this.state.confirmFailed}
          onCancel={() => this.onCancelConfirmation()}
          loading={this.state.confirmInProgress}
          useErrorIcon={this.state.confirmFailed}
          hideConfirmButton={true}
          errorIcon={<i className="fa-solid fa-xmark customer-exit-survey__confirm-window-error-icon"></i>}
        />

      </React.Fragment>
    );
  }
}

export default CustomerExitSurvey
