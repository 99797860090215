import React from 'react';
import { Link } from 'react-router-dom';
import {RedText} from './default_elements';
import logo from '../images/logo_fyd_club.png';
import PopupWindow from './popup_window';
import DefaultInput from '../utils/default_input';
import {SERVICE_FUNCIONAL,
        SERVICE_PILATES,
        SERVICE_GYM,
        SERVICE_BIKE,
        SERVICE_BOOTCAMP,
        DEFAULT_ERROR_MESSAGE} from '../constants';
import * as routes from '../constants';
import {postModel, getPhoneText, getAsLocalDate, getLocalDateIsoString} from '../utils/functions';
import WarningMessage from '../utils/warning_message';
import './main_header.scss'

import leadFormImage_792 from '../images/lead_form_image_792.png';
import leadFormImage_470 from '../images/lead_form_image_470.png';
import leadFormImage_430 from '../images/lead_form_image_430.png';
import leadFormImage_380 from '../images/lead_form_image_380.png';
import leadFormImage_330 from '../images/lead_form_image_330.png';
import leadFormDecorator1 from '../images/lead_form_decorator_1.svg';
import leadFormDecorator2 from '../images/lead_form_decorator_2.svg';
import leadFormDecorator3 from '../images/lead_form_decorator_3.svg';

class MainHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      leadName: '',
      leadEmail: '',
      leadPhone: '',
      leadServices: [],
      showLeadForm: false,
      submittingLeadData: false,
      leadSubmitFailed: false,
      leadSubmitCompleted: false,
      highlights: [],
      warningMessage: "",
      showWarningMessage: false,
      screenWidth: window.innerWidth,
      menuVisible: false
    };

    this.countTime = false;
    this.initialTime = null;

    let lastPopupView = null;

    if (window.localStorage) {
      this.LAST_POPUP_VIEW_KEY = `contact_popup:view_date`;

      lastPopupView = localStorage.getItem(this.LAST_POPUP_VIEW_KEY);
    }

    this.hasAlreadyViewed = false;

    const todayDate = new Date();
    this.today = getLocalDateIsoString(todayDate);

    if (lastPopupView !== null) {
      const lastViewDate = getAsLocalDate(lastPopupView);
      const timeDiff = Math.abs(todayDate.getTime() - lastViewDate.getTime());
      let daysPassed = Math.ceil(timeDiff / (1000 * 3600 * 24));

      if (daysPassed > 1) {
        this.hasAlreadyViewed = false;
      }
      else {
        this.hasAlreadyViewed = true;
      }
    }
  }

  async componentDidMount() {
    this.resizeListener = () => this.updateSize();
    window.addEventListener("resize", this.resizeListener);

    if (!this.hasAlreadyViewed &&
        (this.props.location.pathname === routes.MAIN_PATH ||
         this.props.location.pathname === routes.SERVICES_PATH ||
         this.props.location.pathname === routes.WORKING_HOURS_PATH)) {
      this.countTime = true;
      this.initialTime = Date.now();

      requestAnimationFrame(() => this.checkPopupTimer());
    }
  }

  componentWillUnmount() {
    this.countTime = false;

    window.removeEventListener("resize", this.resizeListener);
  }

  updateSize() {
    this.setState({
      screenWidth: window.innerWidth
    });
  }

  checkPopupTimer() {
    if (!this.countTime) {
      return;
    }

    if ((Date.now() - this.initialTime)/1000 >= 10) {
      this.countTime = false;
      this.setState({showLeadForm: true});
    }
    else {
      requestAnimationFrame(() => this.checkPopupTimer());
    }
  }

  isHighlighted(propertyName) {
    return this.state.highlights.includes(propertyName);
  }

  handleInputChange(event) {
    const target = event.target;
    let value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    if (name.startsWith('leadServices')) {
      const service = name.split(':')[1];

      let leadServices = [...this.state.leadServices];

      if (value) {
        leadServices.push(service);
      }
      else {
        leadServices = leadServices.filter((entry) => entry !== service);
      }

      this.setState({leadServices});
    }
    else {
      this.setState({[name]: value});
    }
  }

  async submitLead() {
    if (process.env.NODE_ENV === 'production') {
      window.gtag('event', 'Experimental class - Form request', {
        'event_category' : 'Request',
        'event_label' : 'Experimental class'
      });
    }

    const highlights = [];

    if(this.state.leadName.length <= 0) {
      highlights.push('leadName');
    }
    if(this.state.leadEmail.match(/.+@.+\..+/) === null) {
      highlights.push('leadEmail');
    }
    if(this.state.leadPhone.length <= 0) {
      highlights.push('leadPhone');
    }

    if(highlights.length > 0) {
      if(highlights.length === 1 && highlights[0] === 'leadEmail' && this.state.leadEmail.length > 0) {
        this.setState({
          highlights: highlights,
          showWarningMessage: true,
          warningMessage: 'Formato do email incorreto.'
        });
      }
      else {
        this.setState({
          highlights: highlights,
          showWarningMessage: true,
          warningMessage: 'Informe os campos destacados abaixo.'
        });
      }

      return;
    }

    this.setState({
      highlights: [],
      showWarningMessage: false,
      submittingLeadData: true
    });

    const data = {
      name: this.state.leadName,
      email: this.state.leadEmail,
      phone: this.state.leadPhone,
      target_services: this.state.leadServices.join(','),
    };

    try {
      if(await postModel(routes.CLIENT_LEAD_POST_API, data) === null) {
        this.setState({
          leadSubmitFailed: true,
          submittingLeadData: false,
          warningMessage: DEFAULT_ERROR_MESSAGE
        });
      }
      else {
        this.setState({
          submittingLeadData: false,
          leadSubmitCompleted: true,
          warningMessage: 'Agradecemos pela confiança e em breve nossa equipe entrará em contato! Nesse meio tempo, sinta-se à vontade para agendar uma aula experimental clicando no link abaixo:'
        });

        if (process.env.NODE_ENV === 'production') {
          window.gtag('event', 'Experimental class - Submit confirmation', {
            'event_category' : 'Submit confirmation',
            'event_label' : 'Experimental class'
          });
        }
      }
    }
    catch(errors) {
      this.setState({
        leadSubmitFailed: true,
        submittingLeadData: false,
        warningMessage: DEFAULT_ERROR_MESSAGE
      });
    }
  }

  getNavLink(text, path, isExternal=false) {
    if(this.props.location.pathname.startsWith(path)) {
      return (
        <li className="main-header__navigation__link-wrapper--selected">
          <p className="main-header__navigation__link--selected">{text}</p>
        </li>
      );
    }

    if(isExternal) {
      return (
        <li className="main-header__navigation__link-wrapper">
          <a
            href={path}
            className="main-header__navigation__link"
            onClick={() => {
              if (process.env.NODE_ENV === 'production') {
                window.gtag('event', `Header - ${text}`, {
                  'event_category' : 'Click',
                  'event_label' : text
                });
              }
            }}
          >
            {text}
          </a>
        </li>
      );
    }
    else {
      return (
        <li className="main-header__navigation__link-wrapper">
          <Link
            className="main-header__navigation__link"
            to={path}
            onClick={() => {
              if (process.env.NODE_ENV === 'production') {
                window.gtag('event', `Header - ${text}`, {
                  'event_category' : 'Click',
                  'event_label' : text
                });
              }
            }}
          >
            {text}
          </Link>
        </li>
      );
    }
  }

  getPopupContent() {
    let titleText;
    if (this.state.leadSubmitFailed) {
      titleText = (
        <span><span className='lead-popup__form__header__text--red'>Falha</span> ao enviar contato</span>
      );
    }
    else if (this.state.leadSubmitCompleted) {
      titleText = (
        <span>Contato <span className='lead-popup__form__header__text--red'>envido</span>!</span>
      );
    }

    const popupImage = (
      <img
        className="lead-popup__image"
        src={leadFormImage_470}
        srcSet={`${leadFormImage_792} 792w,
                 ${leadFormImage_470} 470w,
                 ${leadFormImage_430} 430w,
                 ${leadFormImage_380} 380w,
                 ${leadFormImage_330} 330w`}
        sizes="(max-width: 380px) 330px, (max-width: 450px) 380px, (max-width: 650px) 430px, (max-width: 850px) 330px, (max-width: 1100px) 380px, (max-width: 1250px) 430px, 470px"
        alt=""
      />
    );

    if (this.state.leadSubmitFailed || this.state.leadSubmitCompleted) {
      return (
        <div className='lead-popup'>

          <div className='lead-popup__image-wrapper'>

            {popupImage}

          </div>

          <div className='lead-popup__form--align-top'>

            <header className='lead-popup__form__header'>

              {!this.state.submittingLeadData &&
                <div className='lead-popup__form__header__close-button-container'>

                  <button
                    className="lead-popup__form__header__close-button"
                    onClick={() => {
                      if (this.state.leadSubmitCompleted && window.localStorage) {
                        localStorage.setItem(this.LAST_POPUP_VIEW_KEY, this.today);
                      }

                      this.setState({
                        showLeadForm: false,
                        leadSubmitFailed: false,
                        leadSubmitCompleted: false,
                      });
                    }}
                  >
                    <i className="fas fa-times"></i>
                  </button>

                </div>
              }

              <h3 className='lead-popup__form__header__text'>

                {titleText}

              </h3>

            </header>

            <div className='lead-popup__form__inputs-wrapper'>

              <p className={`lead-popup__form__return-message${this.state.leadSubmitCompleted ? '--success' : ''}`}>{this.state.warningMessage}</p>

            </div>

            <footer className="lead-popup__form__footer">

              {(this.state.leadSubmitCompleted && !this.props.location.pathname.startsWith(routes.EXPERIMENTAL_CLASS_PATH)) &&
                <Link
                  className="lead-popup__form__footer__link"
                  to={routes.EXPERIMENTAL_CLASS_PATH}
                  onClick={() => {
                    if (process.env.NODE_ENV === 'production') {
                      window.gtag('event', 'Lead form - Aula experimental', {
                        'event_category' : 'Click',
                        'event_label' : 'Aula experimental'
                      });
                    }
                  }}
                >
                  <span className="lead-popup__form__footer__link--underlined">Agende sua aula experimental sem compromisso clicando aqui</span>
                </Link>
              }

            </footer>

            <img
              className="lead-popup__form__decorator--1"
              src={leadFormDecorator1}
              alt=''
            />
            <img
              className="lead-popup__form__decorator--2"
              src={leadFormDecorator2}
              alt=''
            />
            <img
              className="lead-popup__form__decorator--3"
              src={leadFormDecorator3}
              alt=''
            />

          </div>

        </div>
      );
    }

    return (
      <div className='lead-popup'>

        <div className='lead-popup__image-wrapper'>

          {popupImage}

        </div>

        <div className='lead-popup__form'>

          <header className='lead-popup__form__header'>

            {!this.state.submittingLeadData &&
              <div className='lead-popup__form__header__close-button-container'>

                <button
                  className="lead-popup__form__header__close-button"
                  onClick={() => {
                    if (window.localStorage) {
                      localStorage.setItem(this.LAST_POPUP_VIEW_KEY, this.today);
                    }

                    this.setState({showLeadForm: false});
                  }}
                >
                  <i className="fas fa-times"></i>
                </button>

              </div>
            }

            <h3 className='lead-popup__form__header__text'>

              <span>Quer ter resultados que sempre sonhou?</span>
              <span className='lead-popup__form__header__text--highlighted'>Nossa equipe vai te explicar como!</span>

            </h3>

          </header>

          <div className='lead-popup__form__inputs-wrapper'>

            <WarningMessage
              className="lead-popup__form__warning-message"
              background="white"
              color="#ff3d53"
              message={this.state.warningMessage}
              onClose={() => {this.setState({highlights: [], showWarningMessage: false})}}
              visible={this.state.showWarningMessage}
            />

            <DefaultInput
              name="leadName"
              isHighlighted={this.isHighlighted("leadName")}
              type="text"
              placeholder="Nome completo"
              maxLength="128"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.leadName}
              autoComplete="name"
              disabled={this.state.submittingLeadData}
            />

            <DefaultInput
              name="leadEmail"
              isHighlighted={this.isHighlighted("leadEmail")}
              type="email"
              placeholder="E-mail"
              maxLength="128"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={this.state.leadEmail}
              autoComplete="email"
              disabled={this.state.submittingLeadData}
            />

            <DefaultInput
              name="leadPhone"
              isHighlighted={this.isHighlighted("leadPhone")}
              type="tel"
              placeholder="Telefone/Whatsapp com DDD"
              maxLength="14"
              handleInputChange={(event) => this.handleInputChange(event)}
              value={getPhoneText(this.state.leadPhone)}
              autoComplete="tel"
              disabled={this.state.submittingLeadData}
            />

            <h4 className='lead-popup__form__label'>Tenho interesse:</h4>

            <div className='lead-popup__form__checkboxes'>

              <div className="lead-popup__form__checkbox-input-container">

                <input
                  type="checkbox"
                  id="gym_service_checkbox"
                  name={`leadServices:${SERVICE_GYM}`}
                  onChange={(event) => this.handleInputChange(event)}
                  className="lead-popup__form__checkbox-input"
                  checked={this.state.leadServices.includes(SERVICE_GYM)}
                  disabled={this.state.submittingLeadData}
                />

                <label htmlFor="gym_service_checkbox" className="lead-popup__form__checkbox-input-label">

                  Musculação

                </label>

              </div>

              <div className="lead-popup__form__checkbox-input-container">

                <input
                  type="checkbox"
                  id="cross_fyd_service_checkbox"
                  name={`leadServices:${SERVICE_FUNCIONAL}`}
                  onChange={(event) => this.handleInputChange(event)}
                  className="lead-popup__form__checkbox-input"
                  checked={this.state.leadServices.includes(SERVICE_FUNCIONAL)}
                  disabled={this.state.submittingLeadData}
                />

                <label htmlFor="cross_fyd_service_checkbox" className="lead-popup__form__checkbox-input-label">

                  Cross FYD

                </label>

              </div>

              <div className="lead-popup__form__checkbox-input-container">

                <input
                  type="checkbox"
                  id="bike_service_checkbox"
                  name={`leadServices:${SERVICE_BIKE}`}
                  onChange={(event) => this.handleInputChange(event)}
                  className="lead-popup__form__checkbox-input"
                  checked={this.state.leadServices.includes(SERVICE_BIKE)}
                  disabled={this.state.submittingLeadData}
                />

                <label htmlFor="bike_service_checkbox" className="lead-popup__form__checkbox-input-label">

                  Bike / Spinning

                </label>

              </div>

              <div className="lead-popup__form__checkbox-input-container">

                <input
                  type="checkbox"
                  id="pilates_service_checkbox"
                  name={`leadServices:${SERVICE_PILATES}`}
                  onChange={(event) => this.handleInputChange(event)}
                  className="lead-popup__form__checkbox-input"
                  checked={this.state.leadServices.includes(SERVICE_PILATES)}
                  disabled={this.state.submittingLeadData}
                />

                <label htmlFor="pilates_service_checkbox" className="lead-popup__form__checkbox-input-label">

                  Pilates

                </label>

              </div>

              <div className="lead-popup__form__checkbox-input-container">

                <input
                  type="checkbox"
                  id="fyd_run_bootcamp_service_checkbox"
                  name={`leadServices:${SERVICE_BOOTCAMP}`}
                  onChange={(event) => this.handleInputChange(event)}
                  className="lead-popup__form__checkbox-input"
                  checked={this.state.leadServices.includes(SERVICE_BOOTCAMP)}
                  disabled={this.state.submittingLeadData}
                />

                <label htmlFor="fyd_run_bootcamp_service_checkbox" className="lead-popup__form__checkbox-input-label">

                  Run Bootcamp

                </label>

              </div>

            </div>

          </div>

          <footer className="lead-popup__form__footer">

            <button
              className="lead-popup__form__submit-button"
              onClick={() => this.submitLead()}
              disabled={this.state.submittingLeadData}
            >

              {this.state.submittingLeadData ? 'Enviando...' : 'Enviar'}

            </button>

            {(!this.state.submittingLeadData && !this.props.location.pathname.startsWith(routes.EXPERIMENTAL_CLASS_PATH)) &&
              <Link
                className="lead-popup__form__footer__link"
                to={routes.EXPERIMENTAL_CLASS_PATH}
                onClick={() => {
                  if (process.env.NODE_ENV === 'production') {
                    window.gtag('event', 'Lead form - Aula experimental', {
                      'event_category' : 'Click',
                      'event_label' : 'Aula experimental'
                    });
                  }
                }}
              >
                <span className="lead-popup__form__footer__link--highlight">Ou</span>
                <span className="lead-popup__form__footer__link--underlined">agende sua aula experimental sem compromisso clicando aqui</span>
              </Link>
            }

          </footer>

          <img
            className="lead-popup__form__decorator--1"
            src={leadFormDecorator1}
            alt=''
          />
          <img
            className="lead-popup__form__decorator--2"
            src={leadFormDecorator2}
            alt=''
          />
          <img
            className="lead-popup__form__decorator--3"
            src={leadFormDecorator3}
            alt=''
          />

        </div>

      </div>
    );
  }

  render() {
    if(this.state.screenWidth <= 750) {
      return (
        <React.Fragment>

          <PopupWindow
              visible={this.state.showLeadForm}
          >

            {this.getPopupContent()}

          </PopupWindow>

          <header className="main-header">

            <div className="main-header__wrapper">

              <div className="main-header__controls-container">

                <Link
                  className="main-header__logo"
                  to={routes.MAIN_PATH}
                  onClick={() => {
                    if (process.env.NODE_ENV === 'production') {
                      window.gtag('event', 'Header - Logo', {
                        'event_category' : 'Click',
                        'event_label' : 'FYD logo'
                      });
                    }
                  }}
                >

                  <img className="main-header__logo__image" src={logo} alt="Logo da FYD" />
                  <p className="main-header__logo__text"><RedText>FYD</RedText> CLUB</p>

                </Link>

                {!this.props.collapse &&
                  <button
                    className="main-header__menu-toggler"
                    type="button"
                    onClick={() => this.setState({menuVisible: !this.state.menuVisible})}
                  >
                    <i className="fas fa-bars"></i>
                  </button>
                }

              </div>

              {!this.props.collapse &&
                <React.Fragment>

                  <div className={`main-header__toggle-container${this.state.menuVisible ? '--visible' : ''}`}>

                    <nav className="main-header__navigation">

                      <ul className="main-header__navigation__links-container">

                        {/* {this.getNavLink('Home', routes.MAIN_PATH)} */}
                        {this.getNavLink('Aula experimental', routes.EXPERIMENTAL_CLASS_PATH)}
                        {this.getNavLink('Preços', routes.SERVICES_PATH)}
                        {this.getNavLink('Horários', routes.WORKING_HOURS_PATH)}
                        {/* {this.getNavLink('Periodização', routes.TRAINING_PERIOD_PATH)} */}

                        <li className="main-header__navigation__link-wrapper">
                          <button
                            className="main-header__navigation__link"
                            type="button"
                            onClick={() => {
                              this.countTime = false;
                              this.setState({showLeadForm: true})
                            }}
                          >
                            Contato
                          </button>
                        </li>

                        {this.getNavLink('Área do aluno', `${window.location.protocol}//aluno.${window.location.host.replace(':3000', ':5000')}`, true)}

                      </ul>

                    </nav>

                  </div>

                  <aside className="main-header__social-media-container">

                    <ul className="main-header__navigation__social-links-container">

                      <li className="main-header__navigation__social-link-wrapper">
                        <a
                          className="main-header__navigation__social-link"
                          href="https://www.facebook.com/fyd.centrotreinamento"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            if (process.env.NODE_ENV === 'production') {
                              window.gtag('event', 'Header - Facebook', {
                                'event_category' : 'Click',
                                'event_label' : 'Facebook'
                              });
                            }
                          }}
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>

                      <li className="main-header__navigation__social-link-wrapper">
                        <a
                          className="main-header__navigation__social-link"
                          href="https://www.instagram.com/fydclub/"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            if (process.env.NODE_ENV === 'production') {
                              window.gtag('event', 'Header - Instagram', {
                                'event_category' : 'Click',
                                'event_label' : 'Instagram'
                              });
                            }
                          }}
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>

                      <li className="main-header__navigation__social-link-wrapper">
                        <a
                          className="main-header__navigation__social-link"
                          href="https://wa.me/5516992883013"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            if (process.env.NODE_ENV === 'production') {
                              window.gtag('event', 'Header - Whatsapp', {
                                'event_category' : 'Click',
                                'event_label' : 'Whatsapp'
                              });
                            }
                          }}
                        >
                          <i className="fab fa-whatsapp"></i>
                        </a>
                      </li>

                    </ul>

                  </aside>

                </React.Fragment>
              }

            </div>

          </header>

          {this.props.enablePromotion && (
            <aside className={`main-header__promotion${this.props.invertPromotionColors ? '--inverted-colors' : ''}`}>

              <div className="main-header__promotion__wrapper">

                {/* <h2 className="main-header__promotion__title"><span className="main-header__promotion__title--yellow">PROMOÇÃO</span>{this.state.screenWidth <= 625 && <br/>} BLACK{this.state.screenWidth <= 360 && <br/>} <span className="main-header__promotion__title--emphasis">NOVE</span>mber</h2> */}
                <h2 className="main-header__promotion__title">TEMOS PLANO {this.state.screenWidth <= 360 && <br/>}<span className="main-header__promotion__title--green">NUTRICIONAL</span></h2> {/*<span className="main-header__promotion__title--emphasis"></span>*/}

                <a
                  href="https://nutri.fydcentrodetreinamento.com.br"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="main-header__promotion__link-button"
                  onClick={() => {
                    if (process.env.NODE_ENV === 'production') {
                      window.gtag('event', 'Header - Botão de promoção - FYD Nutri', {
                        'event_category' : 'Click',
                        'event_label' : 'Plano nutricional - saiba mais'
                      });
                    }
                  }}
                >
                  Saiba mais
                </a>

              </div>

            </aside>
          )}

          <div
            className={`main-header__toggle-background-block${this.state.menuVisible ? '--visible' : ''}`}
            onClick={() => this.setState({menuVisible: !this.state.menuVisible})}
          ></div>

        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <PopupWindow
            visible={this.state.showLeadForm}
        >

          {this.getPopupContent()}

        </PopupWindow>

        <header className="main-header">

          <div className="main-header__wrapper">

            <Link
              className="main-header__logo"
              to={routes.MAIN_PATH}
              onClick={() => {
                if (process.env.NODE_ENV === 'production') {
                  window.gtag('event', 'Header - Logo', {
                    'event_category' : 'Click',
                    'event_label' : 'FYD logo'
                  });
                }
              }}
            >

              <img className="main-header__logo__image" src={logo} alt="Logo da FYD" />
              <p className="main-header__logo__text"><RedText>FYD</RedText> CLUB</p>

            </Link>


            {!this.props.collapse &&
              <div className="main-header__controls-container">

                <nav className="main-header__navigation">

                  <ul className="main-header__navigation__links-container">

                    {/* {this.getNavLink('Home', routes.MAIN_PATH)} */}
                    {this.getNavLink('Aula experimental', routes.EXPERIMENTAL_CLASS_PATH)}
                    {this.getNavLink('Preços', routes.SERVICES_PATH)}
                    {this.getNavLink('Horários', routes.WORKING_HOURS_PATH)}
                    {/* {this.getNavLink('Periodização', routes.TRAINING_PERIOD_PATH)} */}

                    <li className="main-header__navigation__link-wrapper">
                      <button
                        className="main-header__navigation__link"
                        type="button"
                        onClick={() => {
                          this.countTime = false;
                          this.setState({showLeadForm: true})
                        }}
                      >
                        Contato
                      </button>
                    </li>

                    {this.getNavLink(<span>Área<br/>do aluno</span>, `${window.location.protocol}//aluno.${window.location.host.replace(':3000', ':5000')}`, true)}

                  </ul>

                </nav>

                <aside className="main-header__social-media-container">

                  <ul className="main-header__navigation__social-links-container">

                    <li className="main-header__navigation__social-link-wrapper">
                      <a
                        className="main-header__navigation__social-link"
                        href="https://www.facebook.com/fyd.centrotreinamento"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          if (process.env.NODE_ENV === 'production') {
                            window.gtag('event', 'Header - Facebook', {
                              'event_category' : 'Click',
                              'event_label' : 'Facebook'
                            });
                          }
                        }}
                      >
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>

                    <li className="main-header__navigation__social-link-wrapper">
                      <a
                        className="main-header__navigation__social-link"
                        href="https://www.instagram.com/fydclub/"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          if (process.env.NODE_ENV === 'production') {
                            window.gtag('event', 'Header - Instagram', {
                              'event_category' : 'Click',
                              'event_label' : 'Instagram'
                            });
                          }
                        }}
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>

                    <li className="main-header__navigation__social-link-wrapper">
                      <a
                        className="main-header__navigation__social-link"
                        href="https://wa.me/5516992883013"
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          if (process.env.NODE_ENV === 'production') {
                            window.gtag('event', 'Header - Whatsapp', {
                              'event_category' : 'Click',
                              'event_label' : 'Whatsapp'
                            });
                          }
                        }}
                      >
                        <i className="fab fa-whatsapp"></i>
                      </a>
                    </li>

                  </ul>

                </aside>

              </div>
            }

          </div>

        </header>

        {this.props.enablePromotion && (
          <aside className={`main-header__promotion${this.props.invertPromotionColors ? '--inverted-colors' : ''}`}>

            {this.props.invertPromotionColors && <div className="main-header__promotion__background"></div>}

            <div className="main-header__promotion__wrapper">

              <h2 className="main-header__promotion__title">TEMOS PLANO <span className="main-header__promotion__title--green">NUTRICIONAL</span></h2> {/*<span className="main-header__promotion__title--emphasis"></span>*/}

              <a
                href="https://nutri.fydcentrodetreinamento.com.br"
                target="_blank"
                rel="noopener noreferrer"
                className="main-header__promotion__link-button"
                onClick={() => {
                  if (process.env.NODE_ENV === 'production') {
                    window.gtag('event', 'Header - Botão de promoção - FYD Nutri', {
                      'event_category' : 'Click',
                      'event_label' : 'Plano nutricional - saiba mais'
                    });
                  }
                }}
              >
                Saiba mais
              </a>

            </div>

          </aside>
        )}

      </React.Fragment>
    );
  }
}

export default MainHeader
